/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import Validate from './layouts/Validate';
// import ExistingUserPage from 'views/ValidateRoute/ExistingUserPage'
import ExistingUser from './layouts/ExistingUser'

const AuthRedirect = props => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const param = JSON.parse(localStorage.getItem('jobUrlParam'))

    return isLoggedIn ? <DashboardLayout {...props} /> : 
    <Redirect to={`/application/${param?.ClientSuffix}/${param?.JobCode}`} />
}

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/application" />
    },
    {
        path: '/existingUser',
        component: ExistingUser,
        routes: [
            {
                path: '/existingUser/',
                exact: true,
                component: lazy(() => import('views/ExistingUserPage')),
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/application',
        component: Validate,
        routes: [
            {
                path: '/application/',
                exact: true,
                component: lazy(() => import('views/ValidateRoute'))
            },
            {
                path: '/application/:clientID/',
                exact: true,
                component: lazy(() => import('views/ValidateRoute'))
            },
            {
                path: '/application/:clientID/:jobCode',
                exact: true,
                component: lazy(() => import('views/ValidateRoute'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/auth',
        render: (props) => true ? <AuthLayout {...props} /> : <Redirect to="/wizard" />,
        routes: [
            {
                path: '/auth/login',
                exact: true,
                component: lazy(() => import('views/Login'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        route: '*',
        render: props => <AuthRedirect {...props} />,
        routes: [
            {
                path: '/wizard/',
                exact: true,
                component: lazy(() => import('views/Wizard'))
            },

            {
                path: '/confirmation/',
                exact: true,
                component: lazy(() => import('views/ApplicationConfirmation'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('views/Error401'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('views/Error404'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('views/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },

];

export default routes;