import * as actionType from 'actions';

const initialState = {
    errorMessage: {},
    errorStatus: false,
    stateListForCountries: {},
    countyListArr: [],
    isLoading: false,
    isCountryLoading: false,
    jobCountry: {},
};

const common = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_COUNTRY: {
            return {
                ...state,
                isCountryLoading: true,
            };
        }

        case actionType.GET_COUNTRY_SUCCESS: {
            const jobCountryCode = localStorage.getItem('jobUrlCountryCode');
            
            return {
                ...state,
                isCountryLoading: false,
                countries: action.payload.countryList,
                jobCountry: action.payload.countryList.find(country => country.id == jobCountryCode) ?? {}
            };
        }

        case actionType.GET_STATE: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.GET_STATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                stateListForCountries: state?.stateListForCountries?.hasOwnProperty(action?.payload?.countryCode) 
                ? { ...state?.stateListForCountries }
                : { 
                    [action?.payload?.countryCode]: action?.payload?.stateList,
                    ...state?.stateListForCountries 
                }
            };
        }

        case actionType.GET_COUNTY: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.GET_COUNTY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                countyListArr: [...state.countyListArr, action.payload]
            };
        }

        case actionType.SEND_MAIL_ON_LOGOUT: {
            return {
                ...state,
            };
        }

        case actionType.SEND_MAIL_ON_LOGOUT_SUCCESS: {
            return {
                ...state,
            };
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isLoading: false,
                isCountryLoading: false,
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }

        default: {
            return state;
        }
    }
};

export default common;