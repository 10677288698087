import * as actionType from 'actions';

const initialState = {
    isApplicantAdded: false,
    isLoggedIn: false,
    isLoading: false,
    applicantGuid: '',
    email: '',
    loginStatusMessage: '',
    errorMessage: '',
    errorStatus: false,
    jobDetails: {},
    isNotValidPath: false,
    isRegistered: false,
    registerStatusMessage: '',
    isLinkSent: false,
    forgotPassStatusMessage: '',
    isResetLoading: false,
    isReset: false,
    resetPassStatusMessage: '',
    isLoginLoading: false,
    isRegisterLoading: false,
    registerDetails: {},
    loginDetails: {},
    isLogout: false,
    publicToken: '',
    appStartDate: '',
    isValidationRequired: false,
    resendSignUpDetails: {},
    isUserAlreadyExists: false,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_JOB_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.GET_JOB_DETAILS_SUCCESS: {
            localStorage.setItem("jobOpeningGuid", action.payload.jobOpeningGuid);
            localStorage.setItem("jobOpeningUrlGuid", action.payload.jobOpeningUrlGuid);
            localStorage.setItem("jobUrlCountryCode", action.payload.countryId);
            return {
                ...state,
                isLoading: false,
                jobDetails: action.payload,
                isNotValidPath: action.payload.companyName == null ? true : false,
            };
        }

        case actionType.AUTH_LOGIN_POST: {
            localStorage.setItem("user_email", action.payload.userName);

            return {
                ...state,
                isLoginLoading: true,
            };
        }

        case actionType.AUTH_LOGIN_POST_SUCCESS: {
            localStorage.setItem("auth_status", !!action.payload);
            localStorage.setItem("applicantGuid", action.payload?.username);

            return {
                ...state,
                isLoggedIn: !!action.payload,
                applicantGuid: action.payload?.username,
                email: (action.payload?.attributes?.email !== undefined ? action.payload?.attributes?.email : ''),
                loginDetails: action.payload,
                isLoginLoading: false,
            };
        }

        case actionType.AUTH_LOGOUT_POST: {
            return {
                ...state,
            };
        }

        case actionType.AUTH_LOGOUT_POST_SUCCESS: {
            return {
                ...state,
                isLogout: true,
            };
        }

        case actionType.AUTH_REGISTER_POST: {
            localStorage.setItem("user_email", action.payload.username);

            return {
                ...state,
                isRegisterLoading: true,
            };
        }

        case actionType.AUTH_REGISTER_POST_SUCCESS: {
            if (action.payload !== undefined && action.payload.code === "UsernameExistsException") {
                return {
                    ...state,
                    isRegisterLoading: false,
                    errorStatus: false,
                    isUserAlreadyExists: true,
                };
            }

            if (action.payload !== undefined && action.payload.authenticationFlowType === "USER_SRP_AUTH") {
                localStorage.setItem("auth_status", true);

                return {
                    ...state,
                    isRegisterLoading: false,
                    isRegistered: true
                };
            }
            else {
                localStorage.setItem("auth_status", action.payload.status);
                localStorage.setItem("applicantGuid", action.payload.applicantGuid);

                return {
                    ...state,
                    isRegistered: action.payload.status,
                    isLoggedIn: action.payload.status,
                    applicantGuid: action.payload.applicantGuid,
                    registerStatusMessage: action.payload.message,
                    registerDetails: action.payload,
                    isRegisterLoading: false,
                };
            }
        }

        case actionType.AUTH_FORGOT_PASSWORD_VALIDATION_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                forgotPasswordComplete: true
            }
        }

        case actionType.AUTH_FORGOT_PASSWORD_POST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.AUTH_FORGOT_PASSWORD_POST_SUCCESS: {
            return {
                ...state,
                isLinkSent: action.payload,
                forgotPassStatusMessage: action.payload,
                isLoading: false,
            };
        }

        case actionType.AUTH_RESET_PASSWORD: {
            return {
                ...state,
                isResetLoading: true,
            };
        }

        case actionType.AUTH_RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                isReset: action.payload.status,
                resetPassStatusMessage: action.payload.statusMessage,
                isResetLoading: false,
            };
        }

        case actionType.ADD_APPLICANT: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.ADD_APPLICANT_SUCCESS: {
            return {
                ...state,
                isApplicantAdded: action.payload.status,
                isLoading: false,
            };
        }
    
        case actionType.AUTH_RESEND_SIGN_UP_POST: {
            return {
                ...state,
            };
        }

        case actionType.AUTH_RESEND_SIGN_UP_SUCCESS: {
            return {
                ...state,
                resendSignUpDetails: action.payload,
            };
        }

        case actionType.AUTH_RESEND_SIGN_UP_FAILED: {
            return {
                ...state,
                resendSignUpDetails: { ...action.payload, 'isError': true },
            };
        }

        case actionType.RESET_RESEND_SIGN_UP: {
            return {
                ...state,
                resendSignUpDetails: {},
                isRegistered: false,
            };
        }

        case actionType.RESET_USER_ALREADY_EXISTS: {
            return {
                ...state,
                isUserAlreadyExists: false,
            };
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isLoggedIn: false,
                isLoading: false,
                isNotValidPath: false,
                isRegistered: false,
                isLinkSent: false,
                isResetLoading: false,
                isReset: false,
                isLoginLoading: false,
                isRegisterLoading: false,
                isLogout: false,
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }
        default: {
            return state;
        }

        case actionType.ADD_REGISTERED_USER: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.ADD_REGISTERED_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case actionType.GET_HEADERDETAILS_SUCCESS:{
            return{
                ...state,
                publicToken: action.payload.publictoken,
                appStartDate: action.payload.apistartdate,
            }
        }
    }
};

export default auth;